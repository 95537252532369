import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

interface HomePageProps {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        title: string
      }
    }
  }
}
const HomePage: React.FC<HomePageProps> = props => {
  const {
    data: {
      markdownRemark: {
        html,
        frontmatter: { title },
      },
    },
  } = props

  return (
    <Layout>
      <SEO title="Home" />
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export default HomePage

export const contactPageQuery = graphql`
  query HomePage {
    markdownRemark(frontmatter: { templateKey: { eq: "home-page" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
